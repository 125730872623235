@use 'src/styles/mobile/colors.scss';
@import '@/styles/mobile/common.scss';

.contactFooter {
  height: size(36);
  background-color: #F6F8FD;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 1;
  margin-bottom: size(30);

  .p {
    font-family: PingFang SC;
    font-size: size(10);
    font-weight: 400;
    line-height: size(14);
    letter-spacing: 0.09em;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #071325;
  }
}
