@use 'src/styles/mobile/colors.scss';
@use 'src/styles/web/common';
@import '@/styles/mobile/common.scss';

.homeMobile {
  @extend .Section;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: #F8F9FD;
  

  .homeTop {
    width: size(299);
    height: size(299);
    // margin-top: size(76);
    position: absolute;
    top: size(84);
    display: flex;
    flex-direction: column;
    align-items: center;

    z-index: 1;

    .homeTitle {
      width: size(209.27);
      height: size(105.33);
      margin-top: size(54);
    }

    p {
      margin-top: size(9.67);
      margin-bottom: size(12);

      font-family: 'PingFangSC';
      font-style: normal;
      font-weight: 400;
      font-size: size(14);
      line-height: size(20);
      text-align: center;
      color: #000000;

      opacity: 0.2;
    }

    .homeSubtitle {
      width: size(171);
      height: size(20);
    }

    .homeTitleEn {
      width: size(298.11);
      height: size(84);
      margin-top: size(54);
    }

    .homeTitleThai {
      width: size(298.11);
      height: size(84);
      margin-top: size(54);
    }

    .homeSubtitleEn {
      width: size(171);
      height: size(20);
      margin-top: size(28);
    }

    .homeSubtitleThai {
      width: size(171);
      height: size(20);
      margin-top: size(28);
    }
  }

  .commonImage {
    width: size(299);
    height: size(299);
    position: absolute;
    top: size(84);
    left: size(30);
  }

  .commonImageEn   {
    width: size(299);
    height: size(299);
    position: absolute;
    top: size(60);
    left: size(30);
  }

  .commonImageThai   {
    width: size(299);
    height: size(299);
    position: absolute;
    top: size(60);
    left: size(30);
  }

  .homeBottom {
    height: size(253);
    position: absolute;
    top: size(340);

    .homeImage {
      width: 353px;
      height: 253px;
    }
  }

  .homeBottomEn {
    height: size(253);
    position: absolute;
    top: size(317);

    .homeImage {
      width: 353px;
      height: 253px;
    }
  }

  .homeBottomThai {
    height: size(253);
    position: absolute;
    top: size(317);

    .homeImage {
      width: 353px;
      height: 253px;
    }
  }


}