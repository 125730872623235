.SideMenu {
  width: 0;
  height: 100vh;
  z-index: 3;
  position: fixed;
  //left: 9;
  top: 0;
  background-color: #FFFFFF;
  transition: 250ms;

  &.Visible {
    width: 240px;
  }

  .Item {
    height: 54px;
    padding: 19px;
    display: flex;
    align-items: center;
  
    .detailsImage {
      width: 18px;
      height: 18px;
      margin-right: 11px;
    }

    .detailsTitle {
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      color: #071325;
    }
  }
}

.Overlay {
  width: 100%;
  height: 100%;
  z-index: 3;
  position: fixed;
  left: 9;
  top: 0;
  background: #000000;
  opacity: 0.3;
}