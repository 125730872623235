@use 'src/styles/web/common';
@use 'src/styles/web/colors';

.oppContainer {
  @extend .Section;
  background: #F6F8FD;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-bottom: 168px;
  margin-left: 5px;
}

.leftContainer {
  // margin-top: 109px;
  // margin-left: 155.2px;
  // background-color: red;
  padding-right: 66px;

  .headerOpp {
    text-align: left;
    margin-left: 145px;

    .title {
      width: 272px;
      height: 152px;
      position: relative;
      top: -149px;
      left: -116px;
      z-index: 1;
      // font-family: 'DIN';
      // font-style: normal;
      // font-weight: 700;
      // font-size: 50px;
      // line-height: 61px;
      // /* identical to box height */
      // background: radial-gradient(171.53% 267.41% at 73.91% 64.62%, rgba(26, 167, 108, 0) 9.38%, rgba(117, 255, 222, 0.3) 56.77%, rgba(16, 72, 59, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, linear-gradient(0deg, rgba(14, 79, 52, 0.24), rgba(14, 79, 52, 0.24)), radial-gradient(108.55% 110.07% at 61.41% 38.46%, rgba(77, 240, 201, 0) 27.34%, #42E0BA 31.42%, rgba(33, 202, 151, 0) 59.09%, rgba(45, 140, 117, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, linear-gradient(0deg, #01CAB2, #01CAB2), #091D11;
      // background-clip: text;
      // text-shadow: 0px 2px 0px #B0FFE3, 4px 3px 6px #DAE5E2;
      // -webkit-text-fill-color: #01CAB2;
      // text-transform: uppercase;
      // margin-bottom: 13px;

      .oppTitle {
        width: 228px;
        height: 76px;
      }
      .oppTitleEn {
        width: 460px;
        height: 76px;
      }

      .oppTitleThai {
        width: 460px;
        height: 76px;
      }
    }

  }

  //images

  .imgContainer {
    display: flex;
    justify-content: left;
    position: relative;

    .oppLogoImg {
      height: 650px;
      width: 650px;
      position: absolute;
      top: -268px;
      left: -378px;
    }

    .oppImg {
      width: 284px;
      height: 428px;
      // margin-left: -105px;
      position: absolute;
      top: -110px;
      left: 74px;
    }
  }

}

.rightContainer {
  margin-top: 274px;

  .search {
    width: 100%;
    display: flex;
    margin-left: 19px;
    filter: drop-shadow(0px 6px 10px rgba(208, 223, 230, 0.28));

  }

  .searchTerm {
    width: 650px;
    height: 48px;
    border: 6px solid #ffffff;
    border-left: none;
    border-radius: 0 5px 5px 0;
    outline: none;
    background-color: #F2F5F9;
    color: #071325;
    display: flex;

  }

  .searchTermInput {
    width: 630px;
    // height: 36px;
    // border: 6px solid #ffffff;
    padding-left: 20px;
    border: none;
    border-left: none;
    border-radius: 5px;
    outline: none;
    background-color: transparent;
    color: #071325;
  }

  .searchIconSvg {
    margin-right: 8px;
    margin-top: 5px;
    // background-color: red;
    // padding-top: 5px;
    // padding-bottom: 5px;
  }

  // .searchTerm:focus{
  //   color: #00B4CC;
  // }
  .searchTerm::placeholder {
    color: #071325;
    opacity: 0.2;
    padding-left: 20px;
  }

  .searchButton {
    background: #ffffff;
    text-align: center;
    padding-left: 21px;
    padding-right: 12px;
    color: #071325;
    border-radius: 5px 0 0 5px;
    cursor: pointer;
    font-size: 16px;
    border: none;
  }

  /*Resize the wrap to see the search bar change!*/
  .wrap {
    // width: 778px;
    // height: 38px;
    //   width: 30%;
    //   position: absolute;
    //   top: 50%;
    //   left: 50%;
    //   transform: translate(-50%, -50%);
  }

}

.oppCardContainer {
  height: 492px;
  margin-top: 76px;
  margin-bottom: 40px;
  display: flex;
  flex-wrap: wrap;
  //  background-color: #00B4CC;
  width: 900px;
}

.oppEmptyCardContainer {
  height: 350px;
  // margin-top: 76px;
  // margin-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  //  background-color: #00B4CC;
  width: 800px;
}


.emptySearchImg {
  height: 155px;
  width: 249.79px;
}

.emptySearchText {
  font-family: 'PingFangSC';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  /* identical to box height, or 175% */


  color: #000000;

  opacity: 0.4;
  margin-top: 65.25px;
}

.oppCard {
  height: 222px;
  max-width: 166px;
  flex: 1 0 166px;
  margin-left: 19px;
  margin-right: 19px;
  margin-bottom: 24px;
  padding: 7px 10px 15px 20px;

  border: 0.5px solid #EAEBEE;
  border-radius: 8px;
  cursor: pointer;
}

.oppCard:hover {
  cursor: pointer;
  background-image: url("../../../assets/web/job/Maskgroup2x.webp");
  background-color: #fff;
  background-size: cover;
  height: 222px;
  max-width: 166px;
  flex: 1 0 166px;
  margin-left: 19px;
  margin-right: 19px;
  margin-bottom: 24px;
  padding: 7px 10px 15px 20px;

  // border: 0.5px solid #EAEBEE;
  border-radius: 8px;
  box-shadow: 0px 20px 25px #E7F8FF;
}

// .oppCard:hover{
//   cursor: pointer;
//   background-image: url("../../../assets/web/job/Maskgroup2x.webp");
//   background-color: #fff;
//   background-size: cover;
//   // background-attachment: local;

// //  background-attachment: fixed;
//   // height: 194px;
//   // max-width: 166px;
//   // flex: 1 0 166px; 
//   // margin-left: 19px;
//   // margin-right: 19px;
//   // margin-bottom: 24px;
//   // padding:7px 10px 15px 20px;
// // clip-path: circle(26.4% at 34% 0 );

// }

.oppCardHeader {
  display: flex;
  justify-content: space-between;
}

.cardPostTime {

  font-family: 'PingFangSC';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 28px;
  /* identical to box height, or 233% */


  color: #071325;

  opacity: 0.4;
}

.cardButton {

  background-color: transparent;
  font-family: 'PingFangSC';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  cursor: pointer;
  /* identical to box height, or 200% */

  border: none;
  color: #071325;

}

.cardButton:hover {

  color: colors.$primary;
}

.oppCardDetails {
  min-height: 84px;
  margin-top: 27px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;

}

.oppCardName {

  font-family: 'PingFangSC';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  word-wrap: break-word;
  /* identical to box height, or 175% */


  color: #071325;
}

.oppCardSalary {
  font-family: 'PingFangSC';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  /* identical to box height, or 140% */

  letter-spacing: 0.04em;

  color: colors.$primary;
}

.oppCardFooter {
  text-align: left;
  margin-top: 31px;
  font-family: 'PingFangSC';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  /* identical to box height, or 175% */


  color: #071325;
}

.oppCardFooter {
  .headCount {
    color: #27BFC9;
  }
}

.oppPagination {
  width: 100%;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.oppPageItem {
  border: 0.5px solid #EAEBEE;
  border-radius: 2px;

  font-family: 'PingFangSC';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */

  letter-spacing: 0.04em;

  color: #071325;
  padding: 2px 7px;
  margin-right: 13px;
  cursor: pointer;
}

.oppPageItemActive {
  // border: 0.5px solid #EAEBEE;
  border-radius: 2px;
  cursor: pointer;
  background: colors.$primary;
  font-family: 'PingFangSC';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */

  letter-spacing: 0.04em;

  color: #fff;
  padding: 2px 7px;
  margin-right: 13px;
}


.modalContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // background-color: #00000075;
  background: rgba(0, 0, 0, 0.2);
  // background: #FFFFFF;
  // opacity: 0.98;
  // backdrop-filter: blur(115px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 8px;
  z-index: 100;
  font-family: 'PingFangSC';

}

.modalWrapper::-webkit-scrollbar {
  display: none;
}

.modalWrapper {
  position: fixed;

  font-family: 'PingFangSC';
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  min-height: 520px;
  max-height: calc(100vh - 140px);
  overflow-y: auto;
  min-width: 700px;
  max-width: 700px;
  padding-bottom: 30px;

  border-radius: 8px;
}

.modalContent {
  display: flex;
  flex-direction: column;
  // margin-top: 20px;
  font-family: 'PingFangSC';
  // padding: 20px;
  // padding:24px;
  padding: 0px 24px;

}

.modalCloseBtn {
  position: fixed;
  top: 12px;
  right: -14px;
  cursor: pointer;
  color: #292D32;
  opacity: 0.2;
}

.modalCloseBtnImg {
  height: 50%;
  width: 50%;
}

.modalRequirement {
  font-family: 'PingFangSC';
  // padding: 16px 24px;

}

.modalHeadTitle {

  padding: 16px 24px;
  // border-bottom: 1px solid #e8e8e8;
  // color: rgba(0, 0, 0, 0.85);
  // font-weight: 600;
  // font-size: 16px;
  // opacity: 0.05;
  border: 1px solid rgba(0, 0, 0, 0.05);
  font-family: 'PingFangSC';

  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  // font-family: 'PingFangSC';


  color: #071325;
  // background-color: #F7F8F9;
  background: #F7F8F9;
  backdrop-filter: blur(115px);
}

.modalResponse {
  // font-family: 'PingFangSC';
  font-family: 'PingFangSC';
  font-size: 16px;
}

.modalContentTitle {

  font-family: 'PingFangSC';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;

  color: #071325;
  margin-bottom: 5px;
}

.titleExtra {
  font-family: 'PingFangSC';
  margin-left: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 42px;
  width: 238px;
  /* identical to box height */

  text-transform: uppercase;

  color: #071325;

  opacity: 0.2;
  transform: translate(0px, -15px);
}

.titleExtraBillion {
  font-family: 'PingFangSC';
  margin-left: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  width: 274px;
  height: 28px;
  text-transform: uppercase;
  color: #071325;
  opacity: 0.6;
}

.titleExtraEn {
  font-family: 'PingFangSC';
  margin-left: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  width: 315px;
  height: 28px;
  line-height: 28px;
  color: #071325;
}

.titleExtraThai {
  font-family: 'PingFangSC';
  width: 400px;
  margin-left: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #071325;
  opacity: 0.6;
}

.modalTopContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // padding-bottom: 24px;
  padding: 18px 32px 21px 24px;
  font-size: 14px;
  // border-bottom: 1px solid #e8e8e8;
  color: rgba(0, 0, 0, 0.65);

}

.modalTopSubCont {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.modalTopContentItem {
  // margin-right: 24px;
  font-family: 'PingFangSC';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  color: #071325;

  .span {
    // margin-right: 24px;
    font-family: 'PingFangSC';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;

    color: colors.$primary;
  }

  .salaryTitle {
    margin-right: 22px;

    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 31px;

    color: colors.$primary


  }

  .dateTitle {
    color: #071325;
    opacity: 0.5;

    .dateTitleTime {
      color: #071325;
      font-size: 12px;
    }
  }
}


.modalSal {
  padding-left: 20px;
  margin-left: 20px;
}

.modalList {
  list-style-type: decimal;
  font-size: 14px;
  margin-bottom: 24px;
  padding-left: 16px;
  font-family: 'PingFangSC';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  /* or 179% */


  color: #071325;
}