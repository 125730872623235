@use 'src/styles/web/common';

.notFoundPageWeb {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;


  .errorImg {
    width: 617px;
    height: 343px;
  }

  .errorText {
    font-family: 'PingFanSC';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;

    color: #000000;

    opacity: 0.6;
    margin-top: 37.85px;
  }
}
