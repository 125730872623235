.dropdownContainer {
  background-color: #FCEFEF52;
  padding: 5px 20px;
  border: 1px solid #FFFFFF;
  box-shadow: 0px 0px 8px 0px #BEE3D280 inset;
  border-radius: 50px;
  display: flex;
}

.currentLang {
  font-family: PingFang SC;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #071325;
}

.languageItem {
  display: flex;
  align-items: center;
}

.flagIcon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.dropdownArrow {
  width: 16px;
  height: 16px;
  margin-left: 4px;
}
