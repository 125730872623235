@use 'src/styles/web/common';
@use 'src/styles/web/colors';

.home {
   @extend .Section;
   background-color: #F8F9FD;
   display: flex;
   justify-content: center;
   align-items: center;

   .homeContainer {
      width: 1384px;
      height: 753px;    
      margin-left: 80px;
      margin-bottom: -136px;
   }
   
   .contentContainer {
      display: flex;
      position: relative;

      .contentContainerLeft {
         width: 360px;
         height: 200px;
         display: flex;
         flex-direction: column;
         justify-content: space-between;

         .homeTitleCont {
            width: 590px;
            height: 121px;
            
            .homeTitle {
               width: 590px;
               height: 70px;
            }
            .homeTitleEN {
               width: 760px;
               height: 74px;
            }
   
            .homeSubtitle {
               width: 450px;
               
               font-family: 'PingFangSC';
               font-style: normal;
               font-weight: 400;
               font-size: 30px;
               line-height: 42px;
   
               color: #071325;
               opacity: 0.2;
            }
         }

         &Button {
            width: 171px;
            height: 54px;
            padding: 10px 24px;
            font-size: 1.5rem; // 24px;
            color: #FFFFFF;
            background: colors.$primary;
            border-radius: 8px;
            border: none;

            cursor: pointer;
         }

         &TitleZh {
            width: 590px;
            height: 70px;
         }

         &SubTitle {
            width: 435px;
            height: 42px;
            color: #071325;
            opacity: 0.2;
            font-family: 'PingFangSC';
            font-style: normal;
            font-weight: 400;
            font-size: 30px;
            line-height: 42px;
         }

         &ButtonZh {
            width: 171px;
            height: 54px;
            margin-top: 27px;
            padding: 10px 24px;
            font-size: 1.5rem; // 24px;
            color: #FFFFFF;
            background: #13DDAD;
            border-radius: 8px;
            border: none;

            cursor: pointer;
         }
      }

      .contentContainerRight {
         width: 1024px;
         height: 753px;

         &Image {
            margin-top: 20px;
            width: 1023px;
            height: 738px;
         }
      }
   }
}