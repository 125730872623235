@use 'src/styles/mobile/colors.scss';
@use 'src/styles/web/common';
@import '@/styles/mobile/common.scss';

.contactWrapperMobile {
    // @extend .Section;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #F6F8FD;
    position: relative;

    .contactCont {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: size(44);

        z-index: 1;
    
        .titleCont {
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
            // width: 317px;
            height: size(94);
            .contactTitle {
                width: size(168);
                height: size(56);
            }
            .contactTitleEN {
                width: size(224);
                height: size(50);
            }
            .contactTitleThai {
                width: size(224);
                height: size(50);
            }
            .contactSubtitle {
                font-family: 'PingFangSC';
                font-style: normal;
                font-weight: 400;
                font-size: size(16);
                line-height: size(38);
                text-transform: uppercase;
                
                color: #071325;
                opacity: 0.2;
            }
        }
        .contactSubCont {
            width: size(208);
            height: size(542);

            //background: linear-gradient(2.54deg, rgba(255, 255, 255, 0) 6.72%,
            //    rgba(186, 198, 227, 0.252) 140.78%),
            //    linear-gradient(180deg, rgba(248, 249, 253, 0.5382) 0%,
            //    rgba(248, 249, 253, 0.78) 100%);
            //backdrop-filter: blur(11.5px);
            
            border-radius: size(8);

            // .contactBgItem2 {
            //     position: absolute;
            //     width: 422px;
            //     height: 422px;
            //     bottom: -58px;
            //     left: -148px;
            // }

            .itemCont{
                display: flex;
                flex-direction: column;
                align-items: center;
                position: relative;
                
                //width: size(212);
                //height: size(542);
                z-index: 2;

                .contactItem {
                    width: size(208);
                    height: size(128);
                    margin-bottom: size(8);
                    border-radius: size(6);
                    border: 1px solid #F8FAFF;
                    background: #FFFFFF;
                    box-shadow: 0 size(2) size(6) 0 #E4F8FFB2;
                    .contactImg {
                        display: block;
                        margin: size(30) auto;
                    }

                    .itemInfo {
                        color: #071325;
                        font-family: PingFangSC;
                        font-style: normal;
                        font-weight: 400;
                        font-size: size(14);
                        text-align: center;
                        text-underline-position: from-font;
                        text-decoration-skip-ink: none;
                    }
                }
            }
        }
    }

    .contactBackgroundImage {
        width: size(148);
        height: size(159);
        position: absolute;
        bottom: size(-35);
        right: size(2);
    }
}