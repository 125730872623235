@use 'src/styles/mobile/colors.scss';
@import '@/styles/mobile/common.scss';

.dropdownContainer{
  //margin-right: size(16);
}

.languageItem {
  display: flex;
  align-items: center;
}

.flagIcon {
  width: 14px;
  height: 14px;
  margin-right: 4px;
}

.currentLang{
  width: 32px;
  height: 22px;
  font-family: PingFang SC;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #071325;
}

.en{
  width: 22px;
}

.thai{
  width: 43px;
  font-size: 10px;
  //line-height: 16.8px;
}

.dropdownArrow {
  width: 12px;
  height: 12px;
}

