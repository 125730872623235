@use 'src/styles/mobile/colors.scss';
@use 'src/styles/web/common';
@import '@/styles/mobile/common.scss';

.aboutContainer {
  @extend .Section;
  padding-bottom: size(60);
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #F8F9FD;

  .introductionHeader {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .titleContainer {
      // height: max-content;
      display: flex;
      justify-content: flex-start;

      .pageTitleEn {
        height: 80px;
        width: 178px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
      }

      .pageTitleThai {
        height: 80px;
        width: 178px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
      }

      .chTitleContainer{

        .pageTitleCh {
          height: size(56);
          width: size(166);
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
        }
      }

      .p {
        // height: max-content;
        text-align: center;
        font-style: normal;
        font-weight: 400;
        font-size: size(16);
        line-height: size(38);
        /* identical to box height, or 127% */
        text-transform: uppercase;
        color: #071325;
        opacity: 0.2;
      }
    }

    .robot {
      position: absolute;
      right: 30px;
      // margin-right: size(32.9);
      height: size(133);
      width: size(79);
    }
  }

  .aboutDescWrapper {
    // margin-bottom: size(30);
    position: relative;
    height: max-content;
    width: size(351);
    z-index: 1;
    background: linear-gradient(0deg, rgba(241, 243, 249, 0.38), rgba(241, 243, 249, 0.38)), linear-gradient(159.74deg, rgba(255, 255, 255, 0.87) 23.47%, rgba(255, 255, 255, 0.75) 94.95%);
    opacity: 0.85;
    box-shadow: 2px 4px 8px #E9ECF7;
    backdrop-filter: blur(60px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 8px;

    .labelBg {
      position: absolute;
      top: size(11);
      left: size(-1);
      width: max-content;
      height: 28px;
      padding: 0 size(10);
      background-image: url('../../../assets/mobile/about/introduction_title_bg@2x.webp');
      background-repeat: no-repeat;
      background-size: cover;

      .aboutTitle {
        text-align: center;
        font-family: 'PingFangSC';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 30px;
        /* identical to box height, or 250% */
        color: #FFFFFF;
      }
    }

    .aboutDesc {
      // padding-bottom: 9px;
      margin: size(50) size(20) size(20) size(20);
      width: size(311);
      height: max-content;
      // outline: 1px solid red;
      // background: linear-gradient(0deg, rgba(241, 243, 249, 0.38), rgba(241, 243, 249, 0.38)), linear-gradient(159.74deg, rgba(255, 255, 255, 0.87) 23.47%, rgba(255, 255, 255, 0.75) 94.95%);
      // opacity: 0.85;
      // box-shadow: 2px 4px 8px#E9ECF7 ;
      // backdrop-filter: blur(60px);
      /* Note: backdrop-filter has minimal browser support */

      border-radius: 8px;
      font-family: 'PingFangSC';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      /* or 167% */

      text-align: justify;
      letter-spacing: 0.04em;

      /* text light gray */

      color: #071325;

      opacity: 0.5;
    }

    .globe {
      position: absolute;
      right: 0;
      bottom: 0;
      height: 133.90769958496094px;
      width: 132.3384552001953px;
      opacity: 0.2;
      z-index: -1;

    }
  }

  .cardsContainer {

    .cardItem {
      margin-top: size(10);
      // padding: 10px 0;
      height: max-content;
      width: size(351);
      border-radius: 8px;
      background-color: linear-gradient(0deg, rgba(241, 243, 249, 0.38), rgba(241, 243, 249, 0.38)), linear-gradient(159.74deg, rgba(255, 255, 255, 0.87) 23.47%, rgba(255, 255, 255, 0.75) 94.95%);
      box-shadow: 2px 4px 8px #E9ECF7;
      border-radius: 8px;

      .cardCol {
        width: 100%;
        display: flex;
        align-items: center;
        align-items: flex-start;
        height: max-content;

        .cardLeft {
          width: 35%;
          display: flex;
          justify-content: center;
          align-items: center;

          .strategy {
            width: size(100);
            height: auto;
            filter: drop-shadow(0px 14px 15px rgba(140, 153, 169, 0.15));
          }

          .vision {
            width: size(100);
            height: auto;
            filter: drop-shadow(0px 14px 15px rgba(140, 153, 169, 0.15));
          }

          .mission {
            width: size(100);
            height: auto;
            filter: drop-shadow(0px 14px 15px rgba(140, 153, 169, 0.15));
          }
        }

        .cardRight {
          width: 65%;

          .cardDetailWrapper {
            width: 221px;
            padding: size(20) 0;

            .cardDetailTitle {
              // font-family : 'PingFang SC' ;
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 36px;
              text-transform: uppercase;
              color: #071325;
            }

            .cardDetailDesc {
              width: 221px;
              // font-family : 'PingFang SC' ;
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 20px;
              letter-spacing: 0.04em;
              color: #071325;
              opacity: 0.5;
            }
          }
        }
      }
    }
  }


  // 	.card-wrapper{
  // 		height: max-content;
  // 		width: size(351);
  // 		// outline: 1px solid red;

  // 		.card-item{
  // margin-bottom: size(10);
  // height: max-content;
  // width: size(343);
  // border-radius: 8px;
  // display: flex;
  // align-items: center;
  // justify-content: flex-end;
  // // background-image: url('../../../assets/mobile/about/introduction_card@2x.webp');
  // // background-repeat: no-repeat;
  // // background-size: auto;
  // background-color: linear-gradient(0deg, rgba(241, 243, 249, 0.38), rgba(241, 243, 249, 0.38)), linear-gradient(159.74deg, rgba(255, 255, 255, 0.87) 23.47%, rgba(255, 255, 255, 0.75) 94.95%);
  // box-shadow: 2px4px8px#E9ECF7 ;
  // backdrop-filter: blur(60px);
  // /* Note: backdrop-filter has minimal browser support */

  // border-radius: 8px;
  // box-shadow: 2px 4px 8px #E9ECF7 ;
  // backdrop-filter: blur(60px);
  // outline: 1px solid red;


  // 			.aboutDetails{
  // 				display: flex;
  // 				flex-direction: row;
  // 				align-items: center;

  // 				.strategy{
  // 					margin-top: 20px;
  // 					height: max-content;
  // 					width: auto;
  // 				}
  // 				.vision{
  // 					margin-top: 20px;
  // 					margin-right: size(25);
  // 					height: size(75);
  // 					width: auto;
  // 				}
  // 				.mission{
  // 					margin-top: 20px;
  // 					margin-right: size(20);
  // 					height: size(75);
  // 					width: auto;
  // 				}
  // 				.card-detail-wrapper{
  // 					// margin-top: size(10);

  // 					.card-detail-title{
  // 						font-family : 'PingFang SC' ;
  // 						font-style: normal;
  // 						font-weight: 500;
  // 						font-size: 14px;
  // 						line-height: 36px;
  // 						/* identical to box height, or 257% */

  // 						text-transform: uppercase;

  // 						/* text light gray */

  // 						color: #071325;
  // 					}
  // 					.card-detail-desc{
  // 						height: 60px;
  // 						width: 221px;
  // 						font-family : 'PingFang SC' ;
  // 						font-style: normal;
  // 						font-weight: 400;
  // 						font-size: 12px;
  // 						line-height: 20px;
  // 						/* or 167% */

  // 						letter-spacing: 0.04em;

  // 						/* text light gray */

  // 						color: #071325;

  // 						opacity: 0.5;
  // 					}
  // 				}

  // 			}
  // 		}
  // 	}
}