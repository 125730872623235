@use 'src/styles/web/colors';

.Container {
  position: fixed !important;
  // TODO: Replaced with proper stacking instead of using z-index
  z-index: 100;
  display: flex;
  flex-direction: column;
  background-image: none !important;
}

.SideNav {
  position: fixed;
  // TODO: Replaced with proper stacking instead of using z-index
  z-index: 100;
  top: 50%;
  left: 17%;
  line-height: 35px;

  .Item {
    transition: color 0.10s;
    text-align: left;
    font-weight: 400;
    font-size: 20px;
    display: flex;
    align-items: center;

    &:hover {
      cursor: pointer;
      color: colors.$primary;
    }

    .Selected {
      width: 13px;
      margin-left: -18px;
      margin-right: 5px;
    }

    .Line {
      width: 13px;
      margin-right: 11px;
    }
  }

  .Active {
    color: colors.$primary;
  }
}

.Divider {
  width: 8px;
  display: flex;
  flex-direction: column;
  :not(img:last-of-type) {
    margin-bottom: 13px;
  }
}

@media (min-width: 2560px) {
  .SideNav {
    left: 22%;
  }
}

@media (min-width: 1680px) and (max-width: 1919px) {
  .SideNav {
    left: 16.5%;
  }
}

@media (min-width: 1440px) and (max-width: 1679px) {
  .SideNav {
    left: 6%;
  }
}