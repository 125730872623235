@use 'src/styles/web/common';
.bonusWrapper {
    @extend .Section;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #F6F8FD;
    .bonusCont {
        display: flex;
        flex-direction: row;
        .bonusLeft {
            display: flex;
            flex-direction: column;
            position: relative;

            width: 450px;
            min-height: 614px;

            .titleCont {
                height: max-content;
                // margin-bottom: 20px;
                .bonusTitle {
                    width: 228px;
                    height: 76px;
                }
                .bonusTitleEN {
                    width: 460px;
                    height: 76px;
                }
                .bonusTitleTHAI {
                    width: 460px;
                    height: 76px;
                }
                .bonusSubtitle {
                    height: 38px;
                    margin-left: 15px;
                    position: relative;
                    
                    font-family: 'PingFangSC';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 30px;
                    line-height: 38px;
                    text-align: start;
                    
                    text-transform: uppercase;
                    
                    color: #071325;
                    opacity: 0.2;
                    z-index: 1;
                    transform: translate(-4px, -15px);
                }
            }

            .bonusLeftImage {
                width: 650px;
                height: 650px;
                position: absolute;
                top: 49px;
                right: 58px;
            }
            .bonusDesc {
                width: 274px;
                margin-left: 15px;

                font-family: 'PingFangSC';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 28px;

                color: #071325;
                opacity: 0.6;
            }
        }
        .bonusRight {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            // align-content: space-between;
            // justify-content: space-between;
            position: relative;

            width: 862px;
            min-height: 580px;

            .topRow,
            .bottomRow {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                min-height: 274px;

                .bonusItem {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
    
                    width: 262px;
                    min-height: 274px;
                    border-radius: 9px;
                    
                    //Remove if hover effect needs to be returned
                    background: linear-gradient(126.66deg, rgba(255, 255, 255, 0.8526) 27.91%, rgba(255, 255, 255, 0.0098) 94.47%);
                    border: 1px solid #EAEBEE;
    
                    .bonusIcon {
                        width: 110px;
                        height: 112px;
                        margin-bottom: 11px;
                        
                        background-size: cover;
                    }
    
                    .bonusText{
                        display: flex;
                        flex-direction: column;
                        
                        margin-bottom: 20px;
                        .bonusType {
                            // height: 28px;
                            margin-bottom: 13px;

                            font-family: 'PingFangSC';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 24px;
                            line-height: 28px;
                            
                            text-align: center;
                            letter-spacing: 0.09em;
                            color: #071325;
                        }
                        .ul {
                            width: 250px;
                            list-style-type: none;
                            .li {
                                font-family: 'PingFangSC';
                                font-style: normal;
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 22px;
            
                                text-align: center;
                                letter-spacing: 0.09em;
            
                                color: #999999;
                            }
                        }
                    }
                }
                .active, .bonusItem:hover {
                    background: linear-gradient(126.66deg, rgba(255, 255, 255, 0.8526) 27.91%, rgba(255, 255, 255, 0.0098) 94.47%);
                    border: 1px solid #EAEBEE;
                }
            }
            .topRow{
                margin-bottom: 32px;
                
                :nth-child(1) { .bonusIcon{
                    background-image: url('../../../assets/web/bonus/ic_salary@2x.webp');
                    background-image: url('../../../assets/web/bonus/ic_salary_selected@2x.webp');
                } }
                :nth-child(1):hover, .active:nth-child(1) { .bonusIcon{
                    background-image: url('../../../assets/web/bonus/ic_salary_selected@2x.webp');
                } }
                :nth-child(2) { .bonusIcon{
                    background-image: url('../../../assets/web/bonus/ic_leaves@2x.webp');
                    background-image: url('../../../assets/web/bonus/ic_leaves_selected@2x.webp');
                } }
                :nth-child(2):hover, .active:nth-child(2) { .bonusIcon{
                    background-image: url('../../../assets/web/bonus/ic_leaves_selected@2x.webp');
                } }
                :nth-child(3) { .bonusIcon{
                    background-image: url('../../../assets/web/bonus/ic_meals@2x.webp');
                    background-image: url('../../../assets/web/bonus/ic_meals_selected@2x.webp');
                } }
                :nth-child(3):hover, .active:nth-child(3) { .bonusIcon{
                    background-image: url('../../../assets/web/bonus/ic_meals_selected@2x.webp');
                } }
            }
            .bottomRow{
                :nth-child(1) { .bonusIcon{
                    background-image: url('../../../assets/web/bonus/ic_transportation@2x.webp');
                    background-image: url('../../../assets/web/bonus/ic_transportation_selected@2x.webp');
                } }
                :nth-child(1):hover, .active:nth-child(1) { .bonusIcon{
                    background-image: url('../../../assets/web/bonus/ic_transportation_selected@2x.webp');
                } }
                :nth-child(2) { .bonusIcon{
                    background-image: url('../../../assets/web/bonus/ic_holiday@2x.webp');
                    background-image: url('../../../assets/web/bonus/ic_holiday_selected@2x.webp');
                } }
                :nth-child(2):hover, .active:nth-child(2) { .bonusIcon{
                    background-image: url('../../../assets/web/bonus/ic_holiday_selected@2x.webp');
                } }
                :nth-child(3) { .bonusIcon{
                    background-image: url('../../../assets/web/bonus/ic_teambuilding@2x.webp');
                    background-image: url('../../../assets/web/bonus/ic_teambuilding_selected@2x.webp');
                } }
                :nth-child(3):hover, .active:nth-child(3) { .bonusIcon{
                    background-image: url('../../../assets/web/bonus/ic_teambuilding_selected@2x.webp');
                } }
            }
        }
    }
}